import { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    if (window.location.pathname !== "/home") {
      //window.location.href = "https://apps.apple.com/us/app/realest-be-the-realest/id6443488296";
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgb(255, 255, 255)",
        position: 'absolute',
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        justifyContent: "flex-start"
      }}
      className="font-face-gm"
    >
      <h1 style={{textAlign: "left", paddingLeft: "4%", paddingTop: "12%", marginBottom: 20}}>🐝 Realest.</h1>
      <h2 style={{textAlign: "left", paddingLeft: "4%", width: "40%", marginBottom: 20}}>Your Friends, for Real for Real.</h2>
      <h3 style={{textAlign: "left", fontWeight: "400", paddingLeft: "4%", width: "40%"}}>Everyday at a different time, everyone is notified simultaneously to capture and share a Photo in 3 Seconds. A new and unique way to discover who your friends REALLY are in their daily life.</h3>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/gb/app/betweet/id6444735198"
      >
        <img
          src="/appstore_badge.svg"
          alt="Download on the App Store"
          style={{
            height: "auto",
            width: 180,
            marginTop: 10,
            cursor: "pointer",
            position: 'absolute',
            bottom: "10%",
            left: "4%",
          }}
        />
      </a>
      <div style={footerStyle}><Link style={{paddingRight: 20, textAlign: "left",}} to="/terms">Terms</Link><Link style={{paddingRight: 20, textAlign: "left",}} to="/privacy">Privacy</Link></div>
    </div>
  );
}

const footerStyle = {
  textAlign: "center",
  justifyContent: 'center',
  alignItems: 'center',
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "20px",
  width: "100%"
};

export default Home;
