import "./App.css";
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Home from "./components/Home";
import RebealHome from "./components/RebealHome";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Support from "./components/Support";
import RebealTerms from "./components/RebealTerms";
import RebealPrivacy from "./components/RebealPrivacy";
import RebealSupport from "./components/RebealSupport";
import InviteCodePage from "./components/InviteCodePage";
import WebFont from "webfontloader";
WebFont.load({google: {families: ["Roboto:300,400,500,600,800"]}});

if (typeof window !== 'undefined') {
  mixpanel.init('30aea962eaff656acc192cf131786ec1', {
    debug: process.env.NODE_ENV === 'development',
  });
}

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/dump/" element={<RebealHome />} />
          <Route path="/dump/home" element={<RebealHome />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/dump/support" element={<RebealSupport />} />
          <Route path="/dump/privacy" element={<RebealPrivacy />} />
          <Route path="/dump/terms" element={<RebealTerms />} />
          <Route path="/join/:code" element={<InviteCodePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
