import { useEffect } from "react";
import { Link } from "react-router-dom";

function RebealHome() {
  useEffect(() => {
    if (window.location.pathname !== "/home") {
      window.location.href =
        "https://apps.apple.com/us/app/reveal-photo-dump-ur-friends/id6443991773";
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgb(0, 0, 0)",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        justifyContent: "flex-start",
      }}
      className="font-face-gm"
    >
      <h1
        style={{
          color: "white",
          textAlign: "left",
          paddingLeft: "4%",
          paddingTop: "12%",
          marginBottom: 20,
        }}
      >
        Dump
      </h1>
      <h2
        style={{
          color: "white",
          textAlign: "left",
          paddingLeft: "4%",
          width: "40%",
          marginBottom: 20,
        }}
      >
        Photo bomb your friends
      </h2>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/us/app/reveal-photo-dump-ur-friends/id6443991773"
      >
        <img
          src="/appstore_badge.svg"
          alt="Download on the App Store"
          style={{
            height: "auto",
            width: 180,
            marginTop: 10,
            cursor: "pointer",
            position: "absolute",
            bottom: "10%",
            left: "4%",
          }}
        />
      </a>
      <div style={footerStyle}>
        <Link style={{ paddingRight: 20, textAlign: "left" }} to="/terms">
          Terms
        </Link>
        <Link style={{ paddingRight: 20, textAlign: "left" }} to="/privacy">
          Privacy
        </Link>
      </div>
    </div>
  );
}

const footerStyle = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "20px",
  width: "100%",
};

export default RebealHome;
